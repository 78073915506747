import React from "react";
import { useLocation } from "react-router";
import Camera from "../components/Camera";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const Scan = () => {
  const query = useQuery();

  const qLang = query.get("lang") ? query.get("lang") : null;

  if (qLang !== null) {
    localStorage.setItem("lang", qLang);
  }

  return <Camera />;
};

export default Scan;
