import CONTENT_LANG from "./../constants/content_lang";

export default function getText() {
  let lang = "DE";
  lang = localStorage.getItem("lang")
    ? localStorage.getItem("lang").toUpperCase()
    : lang;

  if (!CONTENT_LANG.hasOwnProperty(lang)) {
    lang = "DE";
  }

  //lang = "DE";

  return CONTENT_LANG[lang];
}
