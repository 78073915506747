import React, { useCallback, useEffect, useMemo, useState } from "react";
import Loader from "react-loaders";

import CAMERA from "../constants/camera";
import ImageClassifier from "./../utils/imageClassifier";
import ActionButton from "./shared/ActionButton";
import Scanner from "./Scanner";
import Validation from "./Validation";
import getText from "./../utils/getText";
import ManualCompare from "./shared/ManualCompare";
import getPredictionResult from "./../utils/getPredictionResult";

const _MODEL_URL = "/model/model.json";

let _camera;

const Camera = () => {
  const [status, setStatus] = useState(CAMERA.STATUS.LOADING);
  const [result, setResult] = useState("");

  const model = useMemo(() => new ImageClassifier(), []);

  const modelSetup = useCallback(async () => {
    model.modelURL = _MODEL_URL;
    await model.warmUp();
  }, [model]);

  useEffect(() => {
    modelSetup();
  }, [modelSetup]);
  
  useEffect(() => {
    cameraStart();
  }, []);

  const cameraStart = () => {
    _camera = {
      view: document.querySelector("#camera--view"),
      output: document.querySelector("#camera--output"),
      sensor: document.querySelector("#camera--sensor"),
    };
    // Initialise camera
    if (navigator.mediaDevices) {
      navigator.mediaDevices
        .getUserMedia(CAMERA.CONSTRAINTS)
        .then((stream) => {
          _camera.view.srcObject = stream;
          setStatus(CAMERA.STATUS.READY);
        })
        .catch((error) => {
          console.error("Oops. Something is broken.", error);
          console.log("Error name:", error.name);

          setStatus(CAMERA.STATUS.ERROR);
          setResult(error.name);
        });
    } else {
      setStatus(CAMERA.STATUS.ERROR);
    }
  };

  const scan = async () => {
    setStatus(CAMERA.STATUS.LOADING);

    _camera.sensor.width = _camera.view.videoWidth;
    _camera.sensor.height = _camera.view.videoHeight;
    _camera.sensor.getContext("2d").drawImage(_camera.view, 0, 0);
    _camera.output.src = _camera.sensor.toDataURL("image/png");

    const prediction = await model.runClassification(_camera.sensor);

    const predictionResult = await getPredictionResult(
      prediction,
      _camera.output.src
    );

    setResult(predictionResult);
    setStatus(CAMERA.STATUS.VALIDATION);
  };

  const rescan = () => {
    setResult("");
    setStatus(CAMERA.STATUS.READY);
  };

  let renderContent = "";

  switch (status) {
    case CAMERA.STATUS.VALIDATION:
      renderContent = (
        <>
          <Validation result={result} />
          <ActionButton btnText={getText().SCAN} action={() => rescan()} />
          <ManualCompare width="3vw" />
        </>
      );
      break;
    case CAMERA.STATUS.ERROR:
      renderContent = (
        <>
          <Validation result={result} />
          <ActionButton
            btnText={getText().RELOAD}
            action={() => window.location.reload()}
          />
          <ManualCompare width="3vw" />
        </>
      );
      break;
    default:
      break;
  }

  return (
    <>
      {status === CAMERA.STATUS.LOADING && (
        <div className="scanner-loader">
          <Loader type="line-scale" size="md" />
        </div>
      )}
      <Scanner status={status}>
        <ActionButton btnText={getText().SCAN} action={() => scan()} />
        <ManualCompare width="3vw" />
      </Scanner>
      {renderContent}
    </>
  );
};

export default Camera;
