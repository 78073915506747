import React from "react";

import Result from "./Result";
import Error from "./Error";
import CONTENT_LANG from "./../constants/content_lang";
import getText from "./../utils/getText";

const Validation = ({ result }) => {
  const message = (text, width = "4vw") => {
    return (
      <p style={{ textAlign: "center", fontSize: `${width}`, padding: 20 }}>
        {getText()[text]}
      </p>
    );
  };

  const label = result.label ? result.label.split("_")[0] : "";

  switch (label) {
    case "S1":
    case "S2":
      return <Result label={result.label} />;
      case "PermissionDeniedError":
        case "NotAllowedError":
          return (
            <Error>{message(CONTENT_LANG.MESSAGES.PERMISSION_ERROR_MESSAGE)}</Error>
            );
            
    case "S3":
    case "None":
      return (
        <Error>{message(CONTENT_LANG.MESSAGES.NOT_RECOGNISED_MESSAGE)}</Error>
      );
    default:
      return <Error>{message(CONTENT_LANG.MESSAGES.ERROR_MESSAGE)}</Error>;
  }
};

export default Validation;
