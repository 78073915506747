import React from "react";
import { Redirect, Route, Switch } from "react-router";

import Scan from "./pages/Scan";
import Info from "./pages/Info";
import Impressum from "./pages/Impressum";
import Reference from "./pages/Reference";
import Footer from "./components/shared/Footer";

function App() {
  return (
    <>
      <main className="jts-container">
        <Switch>
          <Route path="/scan" component={Scan} />
          <Route path="/info" component={Info} />
          <Route path="/impressum" component={Impressum} />
          <Route path="/reference" component={Reference} />
          <Redirect from="/" exact to="/scan" />
          <Redirect to="/404" />
        </Switch>
      </main>
      <Footer />
    </>
  );
}

export default App;
