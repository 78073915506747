import * as tf from "@tensorflow/tfjs";
import * as automl from "@tensorflow/tfjs-automl";

export default class ImageClassifier {
  constructor(modelURL = "") {
    this._model = null;
    this._modelURL = modelURL;
    this._backend = "webgl";
  }

  set modelURL(modelURL) {
    this._modelURL = modelURL;
  }

  set backend(backend) {
    this.backend = backend;
  }

  async warmUp() {
    try {
      //Load Model
      this._model = await automl.loadImageClassification(this._modelURL);
      console.log("Model loaded!");

      //Warm Up The Classifier
      await this._model.classify(tf.zeros([224, 224, 3]));
      console.log("Prediction warmed up!");
    } catch (error) {
      console.log(error);
    }
  }

  async runClassification(cameraSensor) {
    try {
      // Make prediction based on Image from Camera
      const predictions = await this._model.classify(cameraSensor);

      // Return result from prediction
      return predictions.reduce(
        (max, x) => (x.prob > max.prob ? x : max),
        predictions[0]
      );
    } catch (error) {
      console.log(error);
      return null;
    }
  }
}
