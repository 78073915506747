import { Link } from "react-router-dom";
import getText from "./../../utils/getText";

const Footer = () => {
  return (
    <footer>
      <div>
        <Link to="/info">{getText().HELP}</Link>
      </div>
      <div>
        <a href="https://jti.com">https://jti.com</a>
      </div>
      <div>
        <Link to="/impressum">{getText().IMPRESSUM}</Link>
      </div>
    </footer>
  );
};

export default Footer;
