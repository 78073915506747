
// get the current year
const currentYear = new Date().getFullYear();

const DE = {
  S1: {
    title: "Das ist ein Pack mit gültigem Warnhinweis",
    warning: "Warnhinweise Serie 1",
    valid: "01.01.2022 - 31.12.2023",
    text: "Gültigkeitsphase vom 01.01.2022-31.12.2023<br>Übergangsphase vom 01.01.2024-31.12.2024<br><b>Bitte verkaufen Sie dieses Paket bis spätestens 31.12.2024.</b>",
    isGood: currentYear < 2024 ? true : false,
  },
  S2: {
    title: "Das ist ein Pack mit ungültigem Warnhinweis",
    warning: "Warnhinweise Serie 2",
    valid: "01.01.2024 - 31.12.2025",
    text: "Gültigkeitsphase vom 01.01.2024-31.12.2025<br>Übergangsphase vom 01.01.2026-31.12.2026<br><b>Bitte verkaufen Sie dieses Paket bis spätestens 31.12.2026.</b>",
    isGood: currentYear >= 2024 ? true : false,
  },
  // S3: {
  //   title: "Das ist ein Pack mit gültigem Warnhinweis",
  //   warning: "Warnhinweise Serie 3",
  //   valid: "01.01.2020 - 31.12.2022",
  //   text: "Gültigkeitsphase vom 01.01.2020-31.12.2021<br>Übergangsphase vom 01.01.2022-31.12.2022<br><span style='color:red; font-weight:800'>Bitte verkaufen Sie dieses Paket bis spätestens 31.12.2022.</span>",
  //   isGood: true,
  // },
  SCAN: "SCAN",
  RELOAD: "RELOAD",
  ALTERNATIV:
    "Alternativ können Sie den Warnhinweis manuell mit der Liste der alten und neuen Warnhinweise abgleichen. Klicken Sie dazu ",
  HERE: "HIER",
  HELP: "Hilfe",
  IMPRESSUM: "Impressum",
  ERROR_MESSAGE:
    "Browser wird nicht unterstützt. Bitte öffnen Sie die Applikation in einem anderen Browser.",
  PERMISSION_ERROR_MESSAGE:
    "Kamerazugriff nicht gestattet.Bitte bestätigen Sie den Zugriff auf die Kamera um das Pack zu scannen.",
  NOT_RECOGNISED_MESSAGE:
    "Dieser Warnhinweis konnte nicht erkannt werden. Versuchen Sie es bitte erneut und achten Sie auf den richtigen Abstand, Winkel und eine helle Umgebung.",
  SCANNER: {
    TITLE: "Scannen Sie hier Ihre Packs:",
    P1: "Achten Sie auf den Abstand und eine helle Umgebung",
  },
  INFO: {
    TITLE: "PACKUNG VERGLEICHEN",
    P1: "Alt oder neu?",
    P2: "Sie sind sich nicht sicher ob die Packung noch den alten Warnhinweis hat oder schon zu den aktuellen gehört? Dann können Sie hier den Warnhinweis der Packung scannen und wir sagen Ihnen um welchen Warnhinweis es sich handelt.",
    P3: "So einfach geht’s:",
    P4: "Warnhinweis der Packung scannen",
    P5: "Ergebnis zum Warnhinweis beachten",
    P6: "Dies ist ein Pack mit altem Warnhinweis.",
    P7: "Bitte verkaufen Sie Packs mit den alten Warnhinweisen noch bis zum 31.12.2022.",
    P8: "Dies ist ein Pack mit neuem Warnhinweis.",
    P9: "Bitte verkaufen Sie ab dem 01.01.2023 nur noch Packs mit den neuen Warnhinweisen.",
    TITLE2: "HINWEISE ZUM SCANNEN",
    L1: "Nur den Warnhinweis scannen, nicht die gesamte Packung",
    L2: "Der Warnhinweis muss beim Scannen innerhalb des Rahmens sein",
    L3: "Für beschädigte Packungen und zerkratzte Warnhinweise, wird Bilderkennung nicht garantiert",
    L4: "Warnhinweis muss bei Scan flach liegen, um Reflektionen zu vermeiden",
    L5: "Warnhinweis darf nicht verkehrt gescannt werden",
    L6: "Gute Lichtverhältnisse",
  },
  REFERENCE: {
    TITLE: "Auf dieser Seite können Sie die Bilder manuell vergleichen.",
  },
};

const FR = {
  S1: {
    title: "Il s'agit d'un paquet avec une étiquette d'avertissement valide",
    warning: "Avertissements Série 1",
    valid: "01.01.2022 - 31.12.2024",
    text: "Phase de transition du 01.01.2022-31.12.2023<br>Phase de validité du 01.01.2024-31.12.2024<br><b>Veuillez vendre ce pack au plus tard le 31.12.2024.</b>",
    isGood: true,
  },
  S2: {
    title:
      "Il s'agit d'un paquet dont l'étiquette d'avertissement n'est pas valide.",
    warning: "Avertissements Série 2",
    valid: "01.01.2024 - 31.12.2026",
    text: "Phase de transition du 01.01.2024-31.12.2025<br>Phase de validité du 01.01.2026-31.12.2026<br><span style='color:red; font-weight:800'>La vente n'est plus autorisée depuis le 01.01.2021.</span>",
    isGood: false,
  },
  // S3: {
  //   title: "Il s'agit d'un paquet avec une étiquette d'avertissement valide",
  //   warning: "Avertissements Série 3",
  //   valid: "01.10.2019 - 31.12.2022",
  //   text: "Phase de transition du 01.01.2020-31.12.2021<br>Phase de validité du 01.01.2022-31.12.2022<br><span style='color:red; font-weight:800'>Veuillez vendre ce pack au plus tard le 31.12.2022.</span>",
  //   isGood: true,
  // },
  SCAN: "SCANNER",
  RELOAD: "RECHARGER",
  ALTERNATIV:
    "Il est aussi possible de comparer manuellement l’avertissement avec la liste des anciens et nouveaux avertissements",
  HERE: "ICI",
  HELP: "Aide",
  IMPRESSUM: "Editeur",
  ERROR_MESSAGE:
    "Le navigateur n’est pas supporté. Merci d’ouvrir l’application dans unb autre navigateur.",
  PERMISSION_ERROR_MESSAGE:
    "L’accès à l’appareil photo n’est pas autorisé. Merci d’autoriser l’accès à l’appareil photo afin de pouvoir scanner le paquet.",
  NOT_RECOGNISED_MESSAGE:
    "L’avertissement n’a pas pu être identifié. Réessayiez et veillez à ce que la distance, l’angle et la luminosité soient corrects.",
  SCANNER: {
    TITLE: "Scannez ici vos paquets:",
    P1: "veillez à maintenir une bonne distance et à avoir une luminosité suffisante.",
  },
  INFO: {
    TITLE: "PACK DE COMPARAISON",
    P1: "Âge ou nouveau?",
    P2: " Vous n'êtes pas sûr si le paquet contient l'ancien avertissement ou déjà entendu parler du courant? Scannez ici l’avertissement figurant sur le paquet et nous vous dirons de quel avertissement il s’agit",
    P3: "La procédure est simple:",
    P4: "Scanner l’avertissement du paquet",
    P5: "Lire le résultat de l’avertissement",
    P6: "Il s’agit d’un paquet muni d’un ancien avertissement.",
    P7: "Veuillez vendre les paquets avec les anciennes mises en garde jusqu’au 31.12.2020.",
    P8: "Il s’agit d’un paquet muni d’un nouvel avertissement",
    P9: "A partir du 01.01.2021, veuillez vendre uniquement les paquets avec les mises en garde actuelles.",
    TITLE2: "INDICATIONS POUR SCANNER",
    L1: "Pour assurer une qualité d’image suffisante, merci de respecter les consignes suivantes:",
    L2: "Scannez uniquement l’avertissement, pas le paquet en entier.",
    L3: "L’avertissement doit se trouver dans le cadre indiqué.",
    L4: "L’avertissement doit être placé à plat afin d’éviter les reflets.",
    L5: "L’avertissement ne peut pas être scanné à l’envers.",
    L6: "La luminosité doit être suffisante.",
  },
  REFERENCE: {
    TITLE: "Sur cette page, vous pouvez comparer les images manuellement.",
  },
};

const MESSAGES = {
  ERROR_MESSAGE: "ERROR_MESSAGE",
  PERMISSION_ERROR_MESSAGE: "PERMISSION_ERROR_MESSAGE",
  NOT_RECOGNISED_MESSAGE: "NOT_RECOGNISED_MESSAGE",
};

const CONTENT_LANG = {
  DE,
  FR,
  MESSAGES,
};

export default CONTENT_LANG;
