import axios from "axios";
import stringSimilarity from "string-similarity";
import { TEXT_LABELS } from "./../constants/textLabels";

const API_URL = "https://phwscan.heberlink-asendorf.ch/ocr-detect/";
const LABELS = ["S1_B", "S1_F", "S2_A", "S2_H", "S3_F", "S3_J"];
const SET_LABELS = new Set(LABELS);

export default async function getPredictionResult(prediction, image) {
  const { prob, label } = prediction;

  if (SET_LABELS.has(label.trim()) && prob > 0.7) {
    const { data } = await axios.post(API_URL, {
      image,
    });

    const ratings = stringSimilarity.findBestMatch(data, TEXT_LABELS);

    if (data !== "" && ratings.bestMatch.rating > 0.6) {
      return {
        prob: ratings.bestMatch.rating,
        label: LABELS[ratings.bestMatchIndex],
      };
    } else {
      return { prob, label: "None" };
    }
  } else {
    return prob > 0.85 ? prediction : { prob, label: "None" };
  }
}
