export const TEXT_LABELS = [
  "Das Risiko, einen Schlaganfall zu bekommen, ist ungefähr 50% höher bei Rauchern als bei Nichtrauchern. Reuchen führt nu Ver und verursacht Her und Hi Vous courez 50% plus de risques d'avoir une crise cardiaque qu'un non-fumeur. Fumer bouche les arteres et provoque den crises cardiaques et des atoques cerbrales Il rischio di colpo apoplettico è di circa 50% superiore tra i fumatori che tra i non fumatori. Bfumo ostrulsce le arterie e provoca infarti e ictus. Rauchstopplinie/Ligne stop-tabac/ V Linea stop tabacco 0848 000 181",

  "Rauchen ist eine ernste Nikotin- abhängigkeit; haben Sie keine Angst, um Hilfe zu bitten. Medizinische Fachpersonen ha das Rauchen autaugeben hoifen Bnon Fumer entraîne une dépendance à la nicotine. N'ayez pas peur de demander de l'aide. Des spécialisten dans le domal vous aident à areter de umer Fumare è una dipendenza seria alla nicotina; non avere paura di chiedere aiuto. Specialisti del settore madilco poesono iutarti a mettere di fumare. Rauchstopplinie/Ligne stop-tabac/ V Linea stop tabacco 0848 000 181",

  "Lebenslange Raucher verlieren im Durchschnitt 14 Jahre ihres Lebens. Wenn Sie rauchen, sterben Se trüher Continuez à fumer, et vous risquez de perdre 14 années de vie. Fumer, e'est vivre moins longtemps. I fumatori cronici perdono una media di 14 anni di vita. Ifumatori muolono prima. Rauchstopplinie/Ligne stop-tabac/ V Linea stop tabacco 0848 000 181",

  "Das Risiko von Herzkranz- Erkrankungen reduziert sich nach 1-jähriger Rauchabstinenz um 50%. Wer den Rau 16dlicher Hegund dan Riniko Réduisez les risques de maladies cardiaques de 50%. Arrêtez de fumer pendant 1 an. Amiter de fumer réduit les risques de cardiaques et pulmonaires mort aladies Il rischio di malattie coronarie si riduce di un 50% dopo 1 anno di astinenza dal fumo. Smettere di fumare riduce rischio di malattin cardiovascolari e polmonari mortali Rauchstopplinie/Ligne stop-tabac/ V Linea stop tabacco 0848 000 181",

  "Sie können es schaffen, wir können helfen. Medizinische Fachpe dan Rauchen autzugeben onen olfen thnen Vous pouvez y arriver. Nous vous aiderons. Des spécialistes dans le domaine médical vous aident à areter de fumer Tu puoi farcela, noi possiamo aiutarti. Specialisti del settore medico possono alutarti a smettere di fumare. Rauchstopplinie/Ligne stop-tabac/ V Linea stop tabacco 0848 000 181",

  "Wählen Sie die Freiheit, wir helfen Ihnen. Hier finden Sie Hll um das Rauchen autzugeben: 0848 000 181/www.raucher chenschadet.ch Choissisez la liberté, nous vous aiderons. Faltos-vous aider pour aveter de fumer: 0848 000 181/www.fumercafaitdumal.ch Scegli la libertà, noi ti aiuteremo. Fatti aiutare a smettere di fumare: 0848 000 181/www.fumarefamale.ch Rauchstopplinie/Ligne stop-tabac/ Linea stop tabacco 0848 000 181",
];
