const STATUS = {
  LOADING: "loading",
  READY: "ready",
  ERROR: "error",
  VALIDATION: "validation",
};

const CONSTRAINTS = {
  video: {
    width: 300,
    height: 300,
    facingMode: {
      exact: "environment",
    },
  },
  audio: false,
};

const CAMERA = {
  STATUS,
  CONSTRAINTS,
};

export default CAMERA;
