import React from "react";
import newLabel from "./../assets/img/new.png";
import oldLabel from "./../assets/img/old.png";
import getText from "./../utils/getText";

const Result = ({ label }) => {
  const serie = label.split("_")[0];
  return (
    <div className="content">
      <h2>{getText()[serie].title}</h2>
      <div
        style={{
          display: "flex",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <div className="result-img">
          <img src={`/labels/${serie}/${label}.jpg`} alt="result-img" />
        </div>
        <div className="result-label">
          <img
            src={getText()[serie].isGood ? newLabel : oldLabel}
            alt="result-label"
          />
        </div>
      </div>

      <p
        className="result-text"
        dangerouslySetInnerHTML={{ __html: getText()[serie].text }}
      ></p>
    </div>
  );
};

export default Result;
