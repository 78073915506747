import React from "react";
import getText from "./../utils/getText";

const Impressum = () => {
  return (
    <div className="content">
      <h2>{getText().IMPRESSUM}</h2>
      <p>
        JT International AG Dagmersellen
        <br />
        Baselstrasse 65
        <br />
        6252 Dagmersellen
        <br />
        <a href="http://www.jti.com">www.jti.com</a>
      </p>
      <p>
        Handelsregisternummer:
        <br />
        CHE-105.927.695
      </p>
    </div>
  );
};

export default Impressum;
