import React from "react";
import newIcon from "../assets/img/new.png";
import oldIcon from "../assets/img/old.png";
import processImg from "../assets/img/jti-scan-process-reference.jpg";
import { Link } from "react-router-dom";
import getText from "./../utils/getText";

const Info = () => {
  return (
    <div className="content">
      <h2>{getText().INFO.TITLE}</h2>
      <p>{getText().INFO.P1}</p>
      <p>{getText().INFO.P2}</p>
      <p>{getText().INFO.P3}</p>
      <ol>
        <li>{getText().INFO.P4}</li>
        <li>{getText().INFO.P5}</li>
      </ol>
      <div className="flex">
        <img className="img-icon" src={oldIcon} alt="oldIcon" />
        <div>
          <p>
            <span style={{ color: "black", fontWeight: "bold" }}>
              {getText().INFO.P6}
            </span>
            <br />
            {getText().INFO.P7}
          </p>
        </div>
      </div>
      <div className="flex">
        <img className="img-icon" src={newIcon} alt="oldIcon" />
        <div>
          <p>
            <span style={{ color: "black", fontWeight: "bold" }}>
              {getText().INFO.P8}
            </span>
            <br />
            {getText().INFO.P9}
          </p>
        </div>
      </div>
      <h2>{getText().INFO.TITLE2}</h2>
      <ul>
        <li>{getText().INFO.L1}</li>
        <li>{getText().INFO.L2}</li>
        <li>{getText().INFO.L3}</li>
        <li>{getText().INFO.L4}</li>
        <li>{getText().INFO.L5}</li>
        <li>{getText().INFO.L6}</li>
      </ul>
      <div style={{ textAlign: "center" }}>
        <img
          style={{ width: "80%", margin: "0 auto" }}
          src={processImg}
          alt="process-img"
        />
      </div>
      <div style={{ margin: "40px auto", textAlign: "center" }}>
        <Link to="/scan" className="btn-action">
          {getText().SCAN}
        </Link>
      </div>
    </div>
  );
};

export default Info;
