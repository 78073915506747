import React from "react";

const ActionButton = (props) => {
  const { btnText, action } = props;
  return (
    <button className="btn-action" onClick={action}>
      {btnText}
    </button>
  );
};

export default ActionButton;
