import React from "react";
import { Link } from "react-router-dom";

import newIcon from "../assets/img/new.png";
import oldIcon from "../assets/img/old.png";
import getText from "./../utils/getText";

const importAll = (r) => {
  return r.keys().map(r);
};

const serie1 = importAll(
  require.context("../assets/labels/S1/", false, /\.(jpg)$/)
);

const serie2 = importAll(
  require.context("../assets/labels/S2/", false, /\.(jpg)$/)
);

// const serie3 = importAll(
//   require.context("../assets/labels/S3/", false, /\.(jpg)$/)
// );

const displayImages = (images) => {
  return (
    <>
      {images.map((image, i) => (
        <div key={`img-${i}`} style={{ maxWidth: "49%", flex: "0 0 50%" }}>
          <img
            alt="type"
            src={image.default}
            style={{ maxWidth: "100%", aspectRatio: 1, padding: ".5vh" }}
          />
        </div>
      ))}
    </>
  );
};

const Reference = () => {
  return (
    <div className="content">
      <h2 style={{ padding: "10px 20px" }}>{getText().REFERENCE.TITLE}</h2>
      <div className="flex-column mt-4">
        <img className="img-icon-w" src={newIcon} alt="labelIcon" />
        <div>
          <h2>
            {getText().S1.warning} ({getText().S1.valid})
          </h2>
        </div>
      </div>
      <div className="flex flex-wrap">{displayImages(serie1)}</div>

      <div
        className="flex-column mt-4"
        style={{ borderTop: "1px solid #efefef", paddingTop: 30 }}
      >
        <img className="img-icon-w" src={oldIcon} alt="labelIcon" />
        <div>
          <h2>
            {getText().S2.warning} ({getText().S2.valid})
          </h2>
        </div>
      </div>
      <div className="flex flex-wrap">{displayImages(serie2)}</div>

      {/* <div
        className="flex-column mt-4"
        style={{ borderTop: "1px solid #efefef", paddingTop: 30 }}
      >
        <img
          className="img-icon-w"
          src={getText().S3.valid ? newIcon : oldIcon}
          alt="labelIcon"
        />
        <div>
          <h2>
            {getText().S3.warning} ({getText().S3.valid})
          </h2>
        </div>
      </div>
      <div className="flex flex-wrap">{displayImages(serie3)}</div> */}
      
      <div style={{ margin: "40px auto", textAlign: "center" }}>
        <Link to="/scan" className="btn-action">
          {getText().SCAN}
        </Link>
      </div>

    </div>
  );
};

export default Reference;
