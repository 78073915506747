import React from "react";
import CAMERA from "../constants/camera";
import getText from "./../utils/getText";

const Scanner = (props) => {
  const { status, children } = props;
  const ScanTitle = <span>{getText().SCANNER.TITLE}</span>;
  const ScanMessage = (
    <span>
      <i>{getText().SCANNER.P1}</i>
    </span>
  );

  return (
    <>
      <div
        className="scanner-container"
        style={{
          display: status !== CAMERA.STATUS.READY ? "none" : "flex",
        }}
      >
        <div className="scan-title">
          <p>
            {ScanTitle}
            <br />
            {ScanMessage}
          </p>
        </div>
        <div id="mainCamera" className="scanner">
          <canvas id="camera--sensor" className="hide"></canvas>
          <video id="camera--view" autoPlay playsInline></video>
          <div id="screenShoot" className="hide">
            <img src="//:0" alt="" id="camera--output" />
          </div>
        </div>

        {children}
      </div>
    </>
  );
};

export default Scanner;
