import React from "react";
import { Link } from "react-router-dom";
import getText from "./../../utils/getText";

const ManualCompare = ({ width = "4vw" }) => {
  return (
    <p
      style={{
        textAlign: "center",
        color: "white",
        fontSize: `${width}`,
        padding: 20,
      }}
    >
      {getText().ALTERNATIV}{" "}
      <Link to="/reference">
        <span style={{ color: "#343a40", textDecoration: "none" }}>
          {getText().HERE}
        </span>
      </Link>
      .
    </p>
  );
};

export default ManualCompare;
